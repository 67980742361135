import moment from "moment"
const states = {
  reports_details: {
    reports: [],
    selected_report: {
      report_item: null,
      filters: [],
      table_data: [],
      table_columns: [],
      current_page: 0,
      has_more_pages: false,
      firstScannedId: "",
      lastScannedId: "",
    },
    selected_filters: {
      startDate: moment(new Date(), "DD-MM-YYYY  HH:mm a")
        .subtract(1, "days")
        .startOf("day"),
      endDate: moment(new Date(), "DD-MM-YYYY  HH:mm a").endOf("day"),
      channels: ""
    },
    selected_worksheet_details: {
      worksheet_id: "",
      report_id: "",
    },
    // Report configuration ->rc
    report_configurations: [],
    rc_has_more: false,
    rc_loading: false,
    rc_load_error: false,
    rc_search_key: "",
    rc_header: {
      enable:false,
      time_zone:{
        default: "",
        options: []
      }
    },
  },
  reports_loading: false,
  requested_reports_loading: false,
}

export default states
